.assetLibraryContainer {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 25px;
  gap: 15px;
  width: 224px;
  background: #f5f8fa;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  outline: none;
  border: none;
}

.searchbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  gap: 10px;
  width: 174px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #abafb3;
  border-radius: 15px;
}

.searchIcon {
  height: 13px;
  width: auto;
}

.searchInput {
  width: 250px;
  outline: none;
  border: none;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  background: transparent;
}

.searchInput::placeholder {
  color: #abafb2;
}

.assets {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0px;
  gap: 15px;
}

.assetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 47.97px;
  height: 46.6px;
  border-radius: 3px;
  cursor: pointer;
}

.assetContainer:hover {
  border: 1px solid #dce2e5;
}


.actionButtons {
  position: fixed;
  right: 30px;
  top: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  z-index: 20;
}

.actionButtonIcon {
  height: 45px;
  width: 45px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dce2e5;
  gap: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: 0.1s ease-in-out;
}

.actionButtonIcon:hover {
  width: fit-content;
  padding: 0px 12px;
}

.buttonText {
  display: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}

.actionButtonIcon:hover .buttonText {
  display: block;
}

.terminal,
.refreshIcon {
  width: 45px !important;
  height: 45px !important;
  background-color:  #dce2e5;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  padding: 6px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.refreshIcon {
  margin: -5px 0px 0px -5px;
}

.terminal:hover,
.refreshIcon:hover {
  transform: scale(1.15);
}
.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 25px;
  gap: 15px;
  border-radius: 30px;
  border: 1px solid #4a62d8;
  background-color: #ffffff;
}
.textInput {
  width: 100%;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  flex-grow: 1;
  border: none;
  outline: none;
  resize: none;
  overflow: auto;
  background-color: transparent;
}

.attachmentIcon {
  cursor: pointer;
  height: 16px;
  width: auto;
}

.recordIcon {
  cursor: pointer;
  height: 18px;
  width: auto;
}

.iconsContainer {
  display: flex;
  gap: 20px;
}

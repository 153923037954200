.promptStarterContainer {
  width: fit-content;
  height: 48px;
  padding: 0px 20px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.icon {
  height: 21px;
  width: auto;
}

.text {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #494b4d;
  white-space: pre-line;
}

.promptStarterContainer:hover {
  background-color: #dce2e5;
}

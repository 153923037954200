.ueButtons {
  position: fixed;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  z-index: 20;
}

.horizontal {
  flex-direction: row;
  transform: translateX(40vw);
  top: 130px;
}

.vertical {
  flex-direction: column;
  left: 30px;
  bottom: 130px
}

.ueButtonIcon {
  height: 45px;
  width: 45px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #dce2e5;
  gap: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
}

.layerIcon {
  height: 35px;
  width: 35px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 5px;
}


.active {
  background-color: #FBBE4F;
}

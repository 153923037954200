.homeContainer {
  box-sizing: border-box;
  margin: 0px auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.slideContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 25px 0px 0px;
}

.promptInput {
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 42px;
}

.dragDropText {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #939699;
  cursor: default;
}

.seeRationaleButton {
  border-radius: 8px !important;
  margin-top: 10px;
}

.actionButtons {
  position: fixed;
  right: 30px;
  top: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  z-index: 10;
}

.actionButtonIcon {
  height: 45px;
  width: 45px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dce2e5;
  gap: 6px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  transition: 0.1s ease-in-out;
}

.actionButtonIcon:hover {
  width: fit-content;
  padding: 0px 12px;
}

.buttonText {
  display: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}

.actionButtonIcon:hover .buttonText {
  display: block;
}

.actionButtonsLegacy {
  position: fixed;
  right: 25px;
  bottom: 36px;
  transition: transform 0.2s ease-in;
  display: flex;
  align-items: center;
  gap: 5px;
}

.actionButtonsLegacy.design {
  right: 50%;
  transform: translateX(50%);
}

.chatBotIcon,
.estimatorIcon,
.terminal,
.refreshIcon {
  width: 41px;
  height: 41px;
  background-color: #4a62d8;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.estimatorIcon {
  background-color: #dce2e5;
  border-color: transparent;
}
.terminal,
.refreshIcon {
  width: 50px !important;
  height: 50px !important;
  background-color: transparent;
  border: none;
  border-radius: 0%;
}

.refreshIcon {
  margin: -5px 0px 0px -5px;
}

.chatBotIcon:hover,
.estimatorIcon:hover,
.terminal:hover,
.refreshIcon:hover {
  transform: scale(1.15);
}

@media (max-width: 840px) {
  .footer {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    gap: 0px;
  }

  .promptInput {
    width: 90%;
    margin: 0px auto;
    margin-bottom: 42px;
  }
}

.sideMenuContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 100%;
  background-color: #131415;
  padding: 16px;
  gap: 24px;
  color: white;
  z-index: 20;

  max-height: 100%;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sideMenuItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.phaseContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px 8px;
  border-radius: 4px;
  background: #1C1C1D;
}

.phaseTextWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: left;
    text-transform: uppercase;
    color: #939699;
  }

  h4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
  }
}

.phaseProgress {
  display: flex;
  height: 4px;
  width: 100%;
  gap: 2px;

  span {
    flex-grow: 1;
    background: #494B4D;
  }
}

.activePhase {
  background: #4A62D8 !important;
}

.listContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.listHeadline {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 2px 8px;
  cursor: pointer;

  h4 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    user-select: none;
  }
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 4px 8px;
  cursor: pointer;
  border-radius: 4px;
  color: #F5F8FA;
  background: #1C1C1D;

  p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    user-select: none;
  }

  div {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.regenerateButton {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  padding: 12px 28px !important;
  border-radius: 8px !important;
  color: #fff !important;
  background: #494B4D !important;

  p {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: center;
  }
}
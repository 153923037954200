.loginContainer {
  box-sizing: border-box;
  margin: 0px auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginWrapper {
  height: 200px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border: 1px solid #bbb;
}

.platformLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  gap: 20px;
  width: 300px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0px 10px;
  margin: 10px;
  border: 1px solid #999;
}

.platformLogin:hover {
  background: #aaa;
}

.googleLogo {
  height: 40px;
  width: auto;
}

.aiInkwellLogo {
  height: 70px;
  width: auto;
}

@media (max-width: 640px) {
  .platformLogin {
    width: 240px;
  }
}

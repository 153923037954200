.ueButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  position: fixed;
  top: 128px;
  right: 56px;
  z-index: 20;
}

.ueButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  padding: 4px;
  gap: 4px;
  background-color: #131415;
  color: #FFF;
}

.ueButtonImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #1C1C1D;
  transition: background-color 0.3s ease;
}

.ueButtonImage:hover {
  background-color: #494B4D;
}

.ueButtonSmallImage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.generalMenu {
  padding: 5px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.menuItem {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.menuText {
  font-size: 12px !important;
  text-transform: capitalize;
}

.generalMenuOpen {
  flex-direction: column;
}

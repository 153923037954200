.console {
  position: fixed;
  top: 40%;
  left: 20%;
  z-index: 1300;
}

.closeIcon {
  position: absolute !important;
  top: -3px;
  right: 0px;
  color: white !important;
}

.consoleTitle {
  position: relative;
  background-color: #333;
  color: white;
  padding: 0px 5px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: move;
}

.consoleContent {
  background-color: black;
  margin-top: -2px;
  color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}

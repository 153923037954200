.dragDropContainer {
  width: 100vw;
  height: 100vh;
  background-color: #fbbe4f;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.dragDropBody {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: clamp(50px, 30%, 250px);
  justify-content: center;
  align-items: center;
}

.headerContainer {
  max-width: 420px;
  color: #ffffff;
}

.headerContainer > p:nth-child(2) {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
}

.headerContainer > p:nth-child(1) {
  font-family: "Poppins";
  font-size: 36px;
  font-weight: 300;
  line-height: 46px;
  text-align: center;
}

.descriptionText {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  max-width: 454px;
}

.exitHover {
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 36px;
  font-weight: 300;
  line-height: 46px;
  text-align: center;
}

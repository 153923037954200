@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
p {
  margin: 0px;
}

.react-resizable-handle {
  z-index: 10;
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(74deg) brightness(108%) contrast(101%);
}

.projectInfo {
  height: 190px;
  display: flex;
  flex-direction: column;
  gap: 10px 25px;
  flex-wrap: wrap;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  color: #abafb2;
}

.projectInfo > p:nth-child(1) {
  font-weight: 500;
  line-height: 14px;
  color: #fff;
}

.main {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.messageContainer {
  padding: 0px 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
}

.userIcon {
  height: 21px;
  width: auto;
  border-radius: 50%;
}

.messageInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: #131415;
}

.message {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #494b4d;
}

.messageOptions {
  width: 62px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.options {
  display: none;
}

.optionIcon {
  height: 14px;
  width: auto;
  cursor: pointer;
}

.main:hover .options {
  display: flex;
  align-items: center;
  gap: 10px;
}

.optionIcon:hover {
  filter: invert(26%) sepia(4%) saturate(431%) hue-rotate(169deg)
    brightness(97%) contrast(86%) brightness(0) saturate(100%);
}

.sourceContainer {
  margin-top: 5px;
  padding-left: 50px;
}

.sourceIcon {
  cursor: pointer;
}

.source {
  padding: 10px;
  background-color: #f5f8fa;
  border-radius: 4px;
}

.dropdown {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 1300;
  width: fit-content;
  padding: 10px;
  background-color: #f5f8fa !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  gap: 5px;
}

.sourceActionsContainer {
  display: flex;
  gap: 10px;
  margin-left: 50px;
}

.sourceActionsContainer > p,
.metrics {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 7px 5px 0px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: #939699;
  cursor: pointer;
  white-space: noWrap;
}
.sourceActionsContainer > p:hover,
.metrics:hover {
  color: #76d0c0;
}

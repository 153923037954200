.mapContainer {
  height: 100vh;
  width: 100%;
}

.body {
  position: relative;
  width: 100%;
  height: calc(100vh - 105px);
  overflow: hidden;
}

.iframe {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  user-select: none;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px;

  input {
    height: 30px;
  }

  button {
    height: 30px;
    font-size: 16px;
    margin-right: 20px;
  }
}

.iframeContainer {
  width: 100%;
  height: 600px;
  display: flex;
  border: 1px solid #808080;
  background-color: #DCDCDC;
}
.checklist {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checklist > p:nth-child(1) {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #ffffff;
}

.actionList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 140px;
  overflow-y: auto;
}

.checklistItem {
  display: flex;
  gap: 25px;
  align-items: center;
}

.checkmarkIcon {
  width: 18px;
  height: auto;
  cursor: pointer;
}

.checklistItem > p {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #abafb2;
}

.notify {
  background-color: transparent;
  border: none;
  height: 20px;
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #494b4d;
  cursor: pointer;
}

.notify:hover {
  background-color: #fff;
  color: #333;
}

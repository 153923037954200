.navBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 41px;
  height: 72px;
  padding: 0px 40px 0 24px;
  background-color: #131415 !important;
  border-bottom: 1px solid #1C1C1D;
  color: #fff;
  flex-shrink: 0;
}

.navBarContent {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding-right: 24px;
  height: 48px;
  border-right: 1px solid #1C1C1D;
  align-items: center;
}

.navBarItem {
  font-size: 14px;
  font-weight: 500;
  line-height: 1px;
  text-align: left;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;
}

.aiInkwellLogo {
  cursor: pointer;
  height: 24px;
  width: auto;
}

.profileIcon {
  cursor: pointer;
  height: 22px;
  width: 22px;
  border-radius: 50%;
}

.profileContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.profile {
  position: absolute;
  top: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  z-index: 10;
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 877px;
}

.customInput {
  width: 100%;
  height: 48px;
  background-color: transparent;
  border: 1px solid #494B4D;
  border-radius: 100px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #fff;
  padding: 12px 48px 12px 19px;
}

.customInput::placeholder {
  color: #494B4D;
  font-family: Poppins, sans-serif;
  opacity: 1;
}

.customInput:focus {
  outline: none;
  border-color: #494B4D;
}

.adornment {
  position: absolute;
  right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #fff;
}

.adornmentIcon {
  width: 14px;
  height: 14px;
}

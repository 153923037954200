.loaderContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #000;
  border-radius: 50%;
  width: 160px;
  height: 160px;
  animation: spinloader 2s linear infinite;
}

.loader img {
  height: 120px;
  width: auto;
  animation: spinlogo 2s linear infinite;
}

@keyframes spinloader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.container {
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  margin-left: 50px;
}

.progressContainer {
  display: flex;
  align-items: center;
}

.progressBar {
  flex-grow: 1;
  height: 10px;
  border-radius: 5px;
}

.score {
  margin-left: 10px;
  font-weight: 500;
}

.title {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #131415;
}

.additionalInfo {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #131415;
}

.resultDisplay {
  display: flex;
  flex-direction: column;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #131415;
}

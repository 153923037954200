.landingContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin: 0 auto;
}

.greeting {
  font-family: "Poppins";
  font-size: 64px;
  font-weight: 500;
  line-height: 80px;
  text-align: left;
  background: linear-gradient(
      89.81deg,
      #fbbe4f 0.33%,
      rgba(251, 190, 79, 0.5) 30%,
      rgba(251, 190, 79, 0) 60%
    ),
    linear-gradient(0deg, #131415, #131415);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  display: inline;
  text-transform: capitalize;
}

.welcomeText {
  display: flex;
  flex-direction: column;
  gap: 35px;
  font-size: 32px;
  font-weight: 300;
  line-height: 38px;
  text-align: left;
  color: #131415;
}

.starters {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.promptStarters {
  display: flex;
  gap: 10px;
}

.searchbar {
  width: 330px;
  height: 30px;
  padding: 0px 15px;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #dce2e5;
  display: flex;
  align-items: center;
  margin: auto;
}

.activeSearchbar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  gap: 10px;
  height: 40px;
  background: #f5f8fa;
  border-bottom: 1px solid #4a62d8;
  border-radius: 15px 15px 0px 0px !important;
}

.searchIcon {
  height: 13px;
  width: auto;
}

.searchInput {
  min-width: 250px;
  width: 100%;
  outline: none;
  border: none;
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  background: transparent;
}

.searchInput::placeholder {
  color: #dce2e5;
}

.searchContainer {
  width: 100%;
  position: relative;
  transition: 3s ease;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: hidden;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .landingContainer {
    width: 90%;
    padding: 20px;
  }

  .greeting {
    font-size: 48px;
    line-height: 60px;
  }

  .welcomeText {
    font-size: 24px;
    line-height: 30px;
  }

  .searchbar {
    width: 100%;
    height: 40px;
  }

  .activeSearchbar {
    height: 50px;
  }
}

@media (max-width: 540px) {
  .landingContainer {
    width: 100%;
    padding: 10px;
  }

  .greeting {
    font-size: 32px;
    line-height: 40px;
  }

  .welcomeText {
    font-size: 18px;
    line-height: 24px;
  }

  .searchbar {
    height: 30px;
    padding: 0 10px;
  }

  .activeSearchbar {
    height: 40px;
    padding: 0 10px;
  }

  .promptStarters {
    flex-direction: column;
    gap: 5px;
  }
}

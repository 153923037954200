.recentProjects {
  width: 100%;
  height: 140px;
  border-radius: 4px;
  background-color: #f5f8fa;
  overflow-y: auto;
}

.dataRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 15px;
  gap: 10px;
  height: 28px;
  color: #939699;
  border: 0.5px solid transparent;
}

.dataRow:hover {
  border: 0.5px solid #abafb2;
  border-radius: 4px;
  color: #494b4d;
}

.cell {
  width: 250px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  cursor: default;
}

.dataRow > p:nth-child(1) {
  min-width: 250px;
}

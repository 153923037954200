.chatTreeContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}

.chatTree {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  gap: 10px;
  border-radius: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid transparent;
}

.active {
  border: 1px solid #dce2e6;
}

.chatTree:hover {
  border: 1px solid #dce2e6;
}

.active:hover {
  background-color: #dce2e5 !important;
}

.chatTreeIcon {
  height: 15px;
  width: auto;
}

.chatTree > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  color: #494b4d;
}

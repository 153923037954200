.modal {
  position: fixed;
  top: calc(50% - 175px);
  left: calc(50% - 302px);
  width: 604px;
  min-height: 150px;
  max-height: 300px;
  background: #f5f8fa;
  border-radius: 8px;
  padding: 25px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px
}

.header {
  display: flex;
  justify-content: flex-end;
}

.closeIcon {
  cursor: pointer;
  width: 12px;
  height: 12px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inputContainer > p:nth-child(1) {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #131415;
}

.nameInput {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  height: 32px;
  background: #ffffff;
  border: none;
  outline: none;
}

.nameInput::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #abafb3;
}

.okButton {
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 18.75px;
  height: 38px;
  background-color: #f5f8fa;
  border: 1px solid #abafb3;
  border-radius: 19px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #939699;
  cursor: pointer;
}

.okButton {
  border: 1px solid #4a62d8;
  color: #4a62d8;
}

.okButton:hover {
  background-color: #dce2e5;
  color: #494b4d;
  border: 1px solid #494b4d;
}

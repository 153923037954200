.drawerPaper {
  width: 750px;
  background-color: #f5f8fa !important;
  padding: 25px;
  border-radius: 0px 7px 7px 0px;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0 10px 0;
  flex-wrap: wrap;
  gap: 5px;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0 15px 0;
}

.infoBox {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  width: 150px !important;
  gap: 10px;
}

.info {
  font-family: "Poppins";
  font-size: 10px !important;
  font-weight: 400;
  color: #abafb2;
}

.tableContainer {
  flex: 1;
  overflow: auto;
}

.pinIcon {
  width: 12px;
  height: auto;
  cursor: pointer;
}

.pinIcon:hover {
  filter: invert(1);
}

.button {
  color: #494b4d;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8.5px 15px;
  border-radius: 24px;
  height: 32px;
  width: 150px;
  cursor: pointer;
  border: 1px solid transparent;
}

.activeButton {
  border: 1px solid #4a62d8 !important;
}

.button:hover,
.actionButton:hover {
  border: 1px solid #dce2e5;
}

.activeButton:hover,
.exportButton:hover {
  background-color: #dce2e5;
}

.actionButton {
  color: #494b4d;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 4px 15px;
  border-radius: 24px;
  height: 24px;
  cursor: pointer;
  border: 1px solid transparent;
}

.exportButton {
  border: 1px solid #dce2e5 !important;
  margin-left: auto;
}

.toolbarContainer {
  height: 105px;
  padding: 10px 25px;
  border-bottom: 1px solid #dce2e5;
  background-color: #000000;
  display: flex;
  justify-content: space-between;
}

.projectInfo {
  width: 731px;
  padding: 5px 10px;
  display: flex;
  gap: 25px;
}

.nameContainer {
  padding: 2px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.nameContainer > p:nth-child(1) {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
  color: #ffffff;
}

.nameContainer > p:nth-child(2) {
  width: fit-content;
  height: 16px;
  padding: 0px 15px;
  gap: 10px;
  border-radius: 24px;
  border: 1px solid #494b4d;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #494b4d;
  cursor: pointer;
}

.nameContainer > p:nth-child(2):hover {
  background-color: #ffffff;
}

.targets {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}

.targets > p:nth-child(1) {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
}

.targets > p:nth-child(2) {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #494b4d;
}

.todoChecklist {
  padding: 7px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.todoChecklist > p:nth-child(1) {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #ffffff;
}

.todoChecklist > p:nth-child(2) {
  height: 16px;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 1px solid #494b4d;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  color: #494b4d;
  cursor: pointer;
}

.todoChecklist > p:nth-child(2):hover {
  background-color: #ffffff;
}

.tools {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.otherTools {
  display: flex;
  gap: 20px;
}

.mainTools {
  display: flex;
  gap: 15px;
}

.mainIconContainer {
  width: 60px;
  height: 50px;
  padding: 6px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  border-radius: 30px;
  cursor: pointer;
}

.iconLabel {
  display: none;
  font-size: 9px;
  font-weight: 600;
  line-height: 13.5px;
  color: #abafb2;
  text-align: center;
}

.mainIconContainer:hover .iconLabel {
  display: block;
}

.mainToolsIcon {
  width: 21px;
  height: 21px;
}

.iconContainer {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: default;
}

.otherToolsIcon {
  width: 60%;
  height: auto;
}

.iconContainer:hover {
  background-color: white;
}

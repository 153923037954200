.footerContainer {
  width: 100%;
  height: 42px;
  padding: 0px 25px 10px 25px;
}

.footer {
  height: 21px;
  display: flex;
  justify-content: space-between;
}

.footerText {
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #939699;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.chatInterface {
  width: 100%;
  max-width: 678px;
  margin: 0px auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}

.chatBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  scrollbar-width: thin;
}

.chatTitle {
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #131415;
}

.conversations {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 25px;
}

.buttons {
  padding: 15px;
  display: flex;
  gap: 10px;
  width: 100%;
}

.continueChoices {
  display: flex;
  gap: 15px;
  padding-left: 15px;
}

.estimatorIcon {
  height: 21px;
  width: auto;
  border-radius: 50%;
}

.continueChatbox {
  padding-top: 6px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 627px;
}

.continueChatbox > p:nth-child(1) {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #494b4d;
}

.brainstormOptions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 5px;
}

.brainstormOptions > p {
  font-family: "Poppins";
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #494b4d;
  padding: 15px 10px 15px 10px;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #dce2e5;
  background-color: #f5f8fa;
  width: fit-content;
  min-width: 300px;
  cursor: pointer;
}

.brainstormOptions > p:hover {
  background-color: #dce2e5;
}

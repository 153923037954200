.projectStatus {
  width: 125px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-size: 9px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-align: center;
  font-family: "Poppins";
  color: #ffffff;
  text-transform: uppercase;
}

.descriptionContainer {
  background: transparent;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  outline: none;
  width: 670px;
  height: fit-content;
  border-radius: 7px;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background-color: #f5f8fa;
}

.tabs {
  height: 32px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.tab {
  width: fit-content;
  height: 100%;
  padding: 0px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: transparent;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #494b4d;
  font-family: "Poppins";
  white-space: noWrap;
  border: none;
}

.active {
  border: 1px solid rgba(220, 226, 229, 1);
}

.attractionName {
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #131415;
}

.mainDetails {
  display: flex;
  gap: 15px;
  justify-content: space-between;
}

.subName {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subName > p:nth-child(1) {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #131415;
}

.attractionImg {
  width: 154px;
  height: 191px;
  border-radius: 4px;
}

.locations {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  gap: 6px;
  width: 154px;
  height: 191px;
  background: #ffffff;
  border-radius: 4px;
}

.locations > p,
.general,
.versionHistory,
.wdiDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #939699;
}

.general {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 15px;
  width: 292px;
  height: 191px;
  background: #ffffff;
  border-radius: 4px;
}

.generalList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  flex-grow: 1;
}

.versionHistory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  gap: 10px;
  width: 154px;
  height: fit-content;
  background: #ffffff;
  border-radius: 4px;
}

.wdiDescription {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  gap: 15px;
  background: #ffffff;
  border-radius: 4px;
  height: 100%;
}

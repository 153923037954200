.progressBar {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  height: 14px;
}

.highlight {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #494b4d;
  border-radius: 8px 0px 0px 8px;
}

.step {
  position: relative;
  z-index: 1;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  color: #494b4d;
  cursor: pointer;
}

.step:hover {
  color: white;
}

.active {
  background-color: #3f51b5;
  color: white;
}
